<template>
  <div>
    <el-dialog
      title="授信详情" 
      :visible.sync="dialogVisible"
      width="30%">
        <img src="../../../assets/sp.png" style="width:100%;" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <div class="authorization-title">
        统一信用评价及授信管理
    </div>
    <div class="authorization-ctrl">
        <div>评级授信记录</div>

        <div>
            <el-button type="primary" size="mini" icon="el-icon-plus">新增</el-button>

            <el-input
                style="margin:0 10px;"
                placeholder="请输入内容"
                size="small"
                v-model="input"
                clearable>
            </el-input>

            <el-button type="primary" size="mini" icon="el-icon-search">搜搜</el-button>
        </div>
    </div>

    <el-table
        :data="tableData"
        border
        style="width: 100%;margin-top:10px;">
        <el-table-column
        prop="entname"
        align="center"
        label="主体名称"
        width="180">
        </el-table-column>
        <el-table-column
        prop="code"
        align="center"
        label="统一社会信用代码"
        width="180">
        </el-table-column>
        <el-table-column
        prop="level"
        align="center"
        label="评级">
        </el-table-column>
        <el-table-column
        prop="date"
        align="center"
        label="最新评级时间">
        </el-table-column>
        <el-table-column 
        prop="detail"
        align="center"  
        label="评级详情">
        <template>
            <img style="width:17px;height:17px;cursor: pointer;" src="../../../assets/images/credit/调用@2x.png" @click="routerTo('M2toM3')">
        </template>
        </el-table-column>
        <el-table-column
        prop="ed"
        align="center"
        label="授信额度">
        </el-table-column>
        <el-table-column
        prop="yxq"
        align="center"
        label="额度有效期">
        </el-table-column>
        <el-table-column
        prop="level"
        align="center"
        label="授信详情">
        <template>
            <img style="width:17px;height:17px;cursor: pointer;" @click="dialogVisible=true" src="../../../assets/images/credit/调用@2x_1.png">
        </template>
        </el-table-column>
    </el-table> 
  </div> 
</template> 
 
<script>
import Vue from 'vue'
export default {
  name:'authorization',
  data() {
    return { 
        tableData: [{
            entname:'康美药业股份有限公司',
            code:'91*************',
            level:'Ba',
            date:'2020-09-21',
            ed:'200 / 人民币元',
            yxq:'2020-09-21',
        }],
        dialogVisible:false
    };
  },
  mounted() {
  },
  methods:{
      routerTo(name){
        localStorage.setItem('dupontEntName','康美药业股份有限公司')
        setTimeout(()=>{
          this.$router.push({name:name})
        },300)
      }
  }
};
</script>

<style lang="less" scoped>
.authorization-title{
    width:100%;
    height:30px;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 31px;
    color: #2DB7F5;
    text-align:center;
    border-bottom: 1px solid #2DB7F5;
}
.authorization-ctrl{
    display:flex;
    margin-top:10px;
    justify-content: space-between;
    >div{
        &:first-child{
            font-size: 18px;
            line-height: 31px;
            font-weight:800;
        }
        display:flex;
    }
}
</style>
